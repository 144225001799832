import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { EpicApiState, EpicApiActions, Member } from '../interfaces/Epic';
import {
  FdiCoverageUrl,
  Coverage,
  AccountStatus,
  AccountStatusEnum,
} from '@shp/shp-epic';
import { parseWrapperData } from '../helpers/wrapperDataUtils';

type EpicApiStateTypes = EpicApiState & EpicApiActions;

const useEpicApiStore = create<EpicApiStateTypes>(
  persist(
    (set, get) => ({
      members: [],
      fdiUrl: undefined,
      accountStatus: undefined,
      premium: undefined,
      costShares: undefined,
      planSpending: undefined,
      coverages: undefined,
      pharmacy: undefined,
      coverageId: undefined,
      filingOrder: undefined,
      provider: undefined,
      networkName: undefined,
      isCalpers: undefined,
      isIFP: undefined,
      isCalpersEGWP: undefined,
      isSHCEGWP: undefined,
      isPOS: undefined,
      isPPO: undefined,
      supplementalBenefits: undefined,
      isMedicare: undefined,
      isFEHB: undefined,
      userDetails: undefined,
      planInfo: undefined,
      coverageName: undefined,
      isCoverageSelected: undefined,
      selectedCoverageId: undefined,
      noPlansExperience: undefined,
      eduAssigned: undefined,
      paperlessBilling: undefined,
      changeCoverage: (coverageId: string) => {
        const coverages = (get() as EpicApiState).coverages;
        const coverageIndex = coverages?.findIndex(
          (coverage: Coverage) => coverage.coverageId === coverageId,
        );
        const coverage =
          coverageIndex !== undefined ? coverages?.[coverageIndex] : undefined;

        if (coverage) {
          set((state: EpicApiStateTypes) => ({
            ...state,
            accountStatus: coverage?.accountStatus,
            premium: coverage?.premium,
            costShares: coverage?.costShare,
            planSpending: coverage?.planSpending,
            pharmacy: coverage?.pharmacy,
            coverageId: coverage?.coverageId,
            isMedicare: coverage?.isMedicare,
            provider: coverage?.provider,
            networkName: coverage?.networkName,
            filingOrder: coverageIndex,
            isFEHB: coverage?.coverageName?.toLowerCase().includes('fehb'),
            isCalpers: coverage?.coverageName
              ?.toLowerCase()
              .includes('calpers'),
            isCalpersEGWP: coverage?.coverageName
              ?.toLowerCase()
              .includes('calpers egwp'),
            isSHCEGWP: coverage?.coverageName
              ?.toLowerCase()
              .includes('sharp healthcare egwp'),
            isIFP: coverage?.coverageName?.toLowerCase().includes('ifp'),
            isPOS: coverage?.planInfo?.productType
              ?.toLowerCase()
              .includes('pos'),
            isPPO: coverage?.planInfo?.productType
              ?.toLowerCase()
              .includes('ppo'),
            supplementalBenefits: coverage.supplementalBenefits,
            planInfo: coverage?.planInfo,
            coverageName: coverage?.coverageName,
            userDetails: {
              id: coverage?.memberInfo.id,
              firstName: coverage?.memberInfo.firstName,
              lastName: coverage?.memberInfo.lastName,
              name: `${coverage?.memberInfo.firstName} ${coverage?.memberInfo.lastName}`,
            },
            isCoverageSelected: true,
            selectedCoverageId: coverageId,
            eduAssigned: coverage?.eduAssigned || false,
            paperlessBilling: coverage?.paperlessBilling || false,
          }));
        }
      },
      resetCoverage: () => {
        set((state: EpicApiStateTypes) => ({
          ...state,
          accountStatus: undefined,
          isCoverageSelected: false,
          selectedCoverageId: undefined,
        }));
      },
      setData: async (data: string) => {
        const coverageInfos = FdiCoverageUrl.parseUrl(data);
        let coverage: Coverage | undefined;
        const selectedCoverageId = (get() as EpicApiState).selectedCoverageId;
        let coverageIndex = 0;
        let isCoverageSelected = false;

        if (coverageInfos) {
          const coverages = coverageInfos.map(
            (info: any) => new Coverage(info),
          );

          if (selectedCoverageId) {
            coverage = coverages.find(
              coverage => coverage.coverageId === selectedCoverageId,
            );
            coverageIndex = coverages.findIndex(
              coverage => coverage.coverageId === selectedCoverageId,
            );
            isCoverageSelected = true;
          } else if (coverages.length === 1) {
            coverage = coverages[0];
            isCoverageSelected = true;
          }

          set((state: EpicApiStateTypes) => ({
            ...state,
            members: parseWrapperData(data)?.proxyMembers as
              | Member[]
              | undefined,
            coveragesInfo: coverageInfos,
            coverages,
            noPlansExperience: !coverageInfos.length,
            accountStatus: coverageInfos[0]
              ? AccountStatus.getAccountStatus(coverageInfos[coverageIndex])
              : AccountStatusEnum.TERMINATED,
            premium: coverage?.premium,
            costShares: coverage?.costShare,
            planSpending: coverage?.planSpending,
            pharmacy: coverage?.pharmacy,
            coverageId: coverage?.coverageId,
            isMedicare: coverage?.isMedicare,
            isFEHB: coverage?.coverageName?.toLowerCase().includes('fehb'),
            provider: coverage?.provider,
            networkName: coverage?.networkName,
            isCalpers: coverage?.coverageName
              ?.toLowerCase()
              .includes('calpers'),
            isCalpersEGWP: coverage?.coverageName
              ?.toLowerCase()
              .includes('calpers egwp'),
            isSHCEGWP: coverage?.coverageName
              ?.toLowerCase()
              .includes('sharp healthcare egwp'),
            isIFP: coverage?.coverageName?.toLowerCase().includes('ifp'),
            isPOS: coverage?.planInfo?.productType
              ?.toLowerCase()
              .includes('pos'),
            isPPO: coverage?.planInfo?.productType
              ?.toLowerCase()
              .includes('ppo'),
            supplementalBenefits: coverage?.supplementalBenefits,
            planInfo: coverage?.planInfo,
            coverageName: coverage?.coverageName,
            filingOrder: coverageIndex,
            isCoverageSelected: isCoverageSelected,
            userDetails: {
              id: coverage?.memberInfo.id,
              eid: parseWrapperData(data)?.MEM_ENCRYPTED_ID_extra?.toString(),
              firstName: coverage
                ? coverage?.memberInfo.firstName
                : parseWrapperData(data)?.MEMBER_NAME?.toString(),
              lastName: coverage?.memberInfo.lastName,
              name: `${coverage?.memberInfo.firstName} ${coverage?.memberInfo.lastName}`,
            },
            eduAssigned: coverage?.eduAssigned || false,
            paperlessBilling: coverage?.paperlessBilling || false,
          }));
        }
      },
      wipeData: () => {
        set((state: EpicApiStateTypes) => ({
          ...state,
          fdiUrl: undefined,
          accountStatus: undefined,
          premium: undefined,
          costShares: undefined,
          planSpending: undefined,
          coverages: undefined,
          coverageId: undefined,
          isMedicare: undefined,
          pharmacy: undefined,
          provider: undefined,
          networkName: undefined,
          isCalpers: undefined,
          isCalpersEGWP: undefined,
          isSHCEGWP: undefined,
          isIFP: undefined,
          isPOS: undefined,
          isPPO: undefined,
          supplementalBenefits: undefined,
          userDetails: undefined,
          planInfo: undefined,
          filingOrder: undefined,
          coverageName: undefined,
          isCoverageSelected: undefined,
          selectedCoverageId: undefined,
          noPlansExperience: undefined,
        }));
      },
    }),
    {
      name: 'epic-api-store',
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state: EpicApiStateTypes) => ({
        selectedCoverageId: state.selectedCoverageId,
        isCoverageSelected: state.isCoverageSelected,
        accountStatus: state.accountStatus,
      }),
    },
  ) as any,
);

export default useEpicApiStore;

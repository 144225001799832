import { useMemo } from 'react';
import useEpicApiStore from './../store/useEpicApiStore';
import { getEpicUrl } from '../helpers/urlUtils';

interface AdditionalProps {
  benefits?: string;
  hasPharmacy: boolean;
  link?: string;
  claims?: string;
  druglist?: string;
  rxReimbursement?: string;
}

export function usePharmacyData() {
  const { pharmacy, isMedicare } = useEpicApiStore();

  const pharmacyInfo = useMemo(() => {
    const additional: AdditionalProps = {
      hasPharmacy: pharmacy?.hasPharmacy || false,
    };
    const baseUrl = pharmacy?.link || '';
    const epicBaseURL = getEpicUrl();

    if (pharmacy?.provider === 'cvs') {
      additional.claims = `${epicBaseURL}inside.asp?mode=fdiredirect&option=cvsClaims`;
      additional.benefits = `${baseUrl}/wps/myportal/MY_PRESCRIPTION_PLAN`;
      additional.druglist = !isMedicare
        ? `${epicBaseURL}inside.asp?mode=fdiredirect&option=cvsDrugCost`
        : `https://medicareplanrx.com/jccf/sharp_formulary_24.html`;
      additional.rxReimbursement = `${baseUrl}/wps/myportal/PRINT_FORMS`;
    } else {
      additional.hasPharmacy = false;
    }

    return {
      ...pharmacy,
      ...additional,
    };
  }, [pharmacy, isMedicare]);

  return pharmacyInfo;
}

import './Coverage.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import { ResourceLinkProps } from '../../interfaces/ResourceLink';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import EpicIframe from '../../components/EpicIframe/EpicIframe';
import PrimaryIconLink from '../../components/PrimaryIconLink/PrimaryIconLink';
import ResourceLink from '../../components/ResourceLink/ResourceLink';
import UserStatus from '../../components/UserStatus/UserStatus';
import useEpicApiStore from '../../store/useEpicApiStore';
import useScrollToView from '../../hooks/useScrollToView';
import TwoColumnCardGrid from '../../components/TwoColumnCardGrid/TwoColumnCardGrid';
import { PrimaryIconLinkProps } from '../../interfaces/PrimaryIconLink';
import IconHeart from '../../components/ShpIcons/IconHeart';
import IconPreventionWellness from '../../components/ShpIcons/IconPreventionWellness';

const Coverage = () => {
  const { t } = useTranslation();
  const { isCalpers, isPPO, coverageId, isMedicare } = useEpicApiStore();
  const eligibilityId = t('routes.coverage.myCoverageId');
  const eligibilityRef = useScrollToView({ id: eligibilityId, block: 'start' });
  const eligibilityLinkRef = useRef('');

  const resourceSectionCards: ResourceLinkProps[] = t(
    'coverage.resourceSection.cards',
    {
      returnObjects: true,
    },
  );

  const ctaBehavioralHealth: PrimaryIconLinkProps = t(
    'coverage.resourceSection.ctaBlocks.behavioralHealth',
    {
      returnObjects: true,
    },
  );

  const ctaWellness: PrimaryIconLinkProps = t(
    'coverage.resourceSection.ctaBlocks.wellness',
    {
      returnObjects: true,
    },
  );

  const EligibilitySectionCards: ResourceLinkProps[] = t(
    'coverage.datatile.cards',
    {
      returnObjects: true,
    },
  );

  if (isPPO) {
    const precertifications: ResourceLinkProps[] = t(
      'coverage.datatile.cardPrecertifications',
      {
        returnObjects: true,
      },
    );
    EligibilitySectionCards.splice(2, 1, precertifications[0]);
  }

  useEffect(() => {
    eligibilityLinkRef.current = `inside.asp?mode=benefitdetail&cvgId=${coverageId}`;

    if (window.location.search.includes('benefits=true')) {
      eligibilityLinkRef.current += '#additionalinfo'; // this anchor value is set from the Epic data tile
    }
  }, [coverageId]);

  return (
    <div data-testid="coverage-page">
      <BreadcrumbLayout pageTitle={`${t('coverage.pageName')}`}>
        <>
          <div className="container coverage__data-tile-card">
            <UserStatus />
            <EpicIframe
              title={t('coverage.datatile.card')}
              url={`CoverageCard?mode=benefitdetail&submode=sharp&cvgId=${coverageId}`}
              width={786}
              height={475}
            />
            {/* <div className="coverage__request-card">
              <PrimaryIconLink
                to={`/${t('routes.coverage.index')}/${t(
                  'routes.coverage.requestIdCard'
                )}`}
                title={t('coverage.card.title')}
                text={' '}
              >
                <IconIdCard />
              </PrimaryIconLink>
            </div> */}
          </div>
          <div>
            <div
              id={eligibilityId}
              className="container coverage__data-tile-eligibility"
              ref={eligibilityRef}
            >
              <h2 className="coverage__data-tile-title">
                {t('coverage.datatile.title')}
              </h2>
              <div className="coverage__data-tile-eligibility_wrapper">
                <EpicIframe
                  title={t('coverage.datatile.eligibility')}
                  url={eligibilityLinkRef.current}
                  width={500}
                  height={918}
                />
              </div>

              <TwoColumnCardGrid>
                <>
                  {Array.isArray(EligibilitySectionCards) &&
                    EligibilitySectionCards.map((card, index) => (
                      <ResourceLink {...card} key={index} />
                    ))}
                </>
              </TwoColumnCardGrid>
            </div>
          </div>
          <div>
            <div className="container coverage__forms">
              <h3 className="coverage__forms-heading">
                {t('coverage.resourceSection.title')}
              </h3>
              <ul className="coverage__forms-list">
                {Array.isArray(resourceSectionCards) &&
                  resourceSectionCards.map((form, index) => {
                    if (isMedicare && form.toMedicare) {
                      form.to = form.toMedicare;
                    } else if (isCalpers && form.toCalpers) {
                      form.to = form.toCalpers;
                    }
                    return (
                      <li key={index}>
                        <ResourceLink {...form} />
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div>
            <div className={'container coverage__cta-blocks'}>
              {ctaBehavioralHealth.to && (
                <div className={'block'}>
                  <PrimaryIconLink {...ctaBehavioralHealth}>
                    <IconHeart />
                  </PrimaryIconLink>
                </div>
              )}
              {ctaWellness.to && (
                <div className={'block'}>
                  <PrimaryIconLink {...ctaWellness}>
                    <IconPreventionWellness />
                  </PrimaryIconLink>
                </div>
              )}
            </div>
          </div>
        </>
      </BreadcrumbLayout>
    </div>
  );
};

export default Coverage;

import './EpicIframe.scss';
import { FC, useEffect, useRef } from 'react'; // Import useEffect and useRef
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { getEpicUrl, replaceLangParam } from '../../helpers/urlUtils';
import useLoginStore from '../../store/LoginStore';
import useWindowDimensions from '../../hooks/useWindowDimensions';

interface EpicIframeProps {
  title: string;
  url: string;
  width?: number;
  height?: number;
  scrolling?: string;
  refetchEpicDataOnMessage?: string;
  isLogin?: boolean;
}

const ALLOWED_ORIGINS = [
  'https://dev.member.aws-nonprod.sharphealthplan.com',
  'https://stage.member.aws-nonprod.sharphealthplan.com',
  'https://prod.member.aws-prod.sharphealthplan.com',
  'https://rel.member.aws-prod.sharphealthplan.com',
  'https://member.sharphealthplan.com',
];

const EpicIframe: FC<EpicIframeProps> = ({
  title,
  url,
  width = 800,
  height = 500,
  scrolling = 'yes',
  refetchEpicDataOnMessage,
  isLogin = false,
}) => {
  const { i18n } = useTranslation();
  const epicBaseUrl = getEpicUrl();
  const [searchParams] = useSearchParams();
  const dimensions = useWindowDimensions();
  const hasTiles = searchParams.get('showtiles') === 'true';
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { setNeedsDataRefresh } = useLoginStore();

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.source !== iframeRef.current?.contentWindow) return;

      if (ALLOWED_ORIGINS.includes(event.origin)) {
        if (event.data === refetchEpicDataOnMessage) {
          console.log(
            `PostMessage refetchEpicData triggered by message: ${event.data}`,
          );
          setNeedsDataRefresh(true);
        }
      } else {
        console.warn(
          `Received message from disallowed origin: ${event.origin}`,
        );
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [refetchEpicDataOnMessage, setNeedsDataRefresh]);

  const replaceMobileParam = (url: string) => {
    let newUrl = url;
    if (url.includes('mobile=')) {
      return url;
    } else if (dimensions.width > 600) {
      newUrl = url + '&mobile=0';
    } else {
      newUrl = url + '&mobile=1';
    }
    return newUrl;
  };

  return (
    <iframe
      ref={iframeRef}
      className={`${hasTiles ? '-outline' : ''} ${isLogin ? '-login' : ''}`}
      data-testid="EpicIframe"
      id={`mychartframe-${title}`}
      name="mychartframe"
      src={replaceMobileParam(
        replaceLangParam(epicBaseUrl + url, i18n.resolvedLanguage ?? 'en'),
      )}
      width={width}
      height={height}
      title={title}
      scrolling={scrolling}
    ></iframe>
  );
};

export default EpicIframe;

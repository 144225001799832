import './CoverageForms.scss';
import { useTranslation } from 'react-i18next';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import ResourceLink from '../../components/ResourceLink/ResourceLink';
import useEpicApiStore from '../../store/useEpicApiStore';
import { getCoverageEditForms } from '../../helpers/sharedLibrary';

type FormCardProp = {
  label: string;
  link: string;
  openInNewTab?: boolean;
};

const CoverageForms = () => {
  const { t } = useTranslation();
  const { planInfo, isMedicare, isCalpers, isIFP } = useEpicApiStore();
  const formCards = getCoverageEditForms(planInfo?.forms.coverage || [], t);
  const formCards2 = [];
  const isSubscriber = planInfo?.relToSub;

  if (!isCalpers && !isMedicare && !isIFP) {
    formCards2.push({
      label: t('coverage.forms.forms.declareDomestic.title'),
      link: t('coverage.forms.forms.declareDomestic.link'),
      openInNewTab: true,
    });
  }

  if (isMedicare) {
    formCards2.push({
      label: t('coverage.forms.forms.lateEnrollPenalty.title'),
      link: t('coverage.forms.forms.lateEnrollPenalty.link'),
      openInNewTab: true,
    });
  }

  return (
    <div data-testid="coverage-forms">
      <BreadcrumbLayout
        pageTitle={`${t('coverage.forms.pageName')}`}
        className="coverage-forms"
      >
        <>
          {(isSubscriber && (
            <>
              {formCards.length > 0 && (
                <div>
                  <div className="container coverage-forms__forms">
                    {formCards.map((form, index) => (
                      <ResourceLink
                        to={form.to}
                        text={form.text}
                        key={index}
                        openInNewTab={form.openInNewTab}
                      />
                    ))}
                  </div>
                </div>
              )}
              {formCards2.length > 0 && (
                <div>
                  <div className="container coverage-forms__forms">
                    {formCards2.map((form: FormCardProp, index) => (
                      <ResourceLink
                        to={form.link}
                        text={form.label}
                        key={index}
                        openInNewTab={form.openInNewTab}
                      />
                    ))}
                  </div>
                </div>
              )}
            </>
          )) || (
            <div className="container coverage-forms__forms">
              <p>{t('coverage.forms.notSubscriber')}</p>
            </div>
          )}
        </>
      </BreadcrumbLayout>
    </div>
  );
};

export default CoverageForms;

import './CoverageDocuments.scss';
import { useTranslation } from 'react-i18next';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import ResourceLink from '../../components/ResourceLink/ResourceLink';
import LinkAccordion from '../../components/AccordionLinks/AccordionLinks';
import useEpicApiStore from '../../store/useEpicApiStore';
import { useSupplementCare } from '../../hooks/useSupplementalCare';

type DocumentType = {
  documentType: string;
  url: string;
};

type FormCardProp = {
  label: string;
  link: string;
  openInNewTab?: boolean;
};

const CoverageDocuments = () => {
  const { t } = useTranslation();
  const { planInfo, isMedicare } = useEpicApiStore();

  const supplementalCare = useSupplementCare();

  const getMemberHandbooks = (): FormCardProp[] => {
    const handbooks: FormCardProp[] = [];
    planInfo?.documents?.handbooks?.forEach((handbook: DocumentType) => {
      handbooks.push({
        label: handbook.documentType,
        link: handbook.url,
        openInNewTab: true,
      });
    });
    return handbooks;
  };

  const getSummaryDocs = (): FormCardProp[] => {
    const summaryDocs: FormCardProp[] = [];

    if (planInfo?.documents?.summaryOfBenefits) {
      summaryDocs.push({
        label: t('coverage.documents.forms.summaryBenefits.title'),
        link: planInfo.documents.summaryOfBenefits,
        openInNewTab: true,
      });
    }

    if (!isMedicare && planInfo?.documents?.summaryOfCoverage) {
      summaryDocs.push({
        label: t('coverage.documents.forms.summaryBenefitsCoverage.title'),
        link: planInfo.documents.summaryOfCoverage,
        openInNewTab: true,
      });
    }

    return summaryDocs;
  };

  const getOtherCoverages = (): FormCardProp[] => {
    const otherCoverages: FormCardProp[] = [];
    planInfo?.documents?.otherCoverages?.forEach((item: DocumentType) => {
      otherCoverages.push({
        label: item.documentType,
        link: item.url,
        openInNewTab: true,
      });
    });

    if (supplementalCare) {
      supplementalCare.forEach((item: any) => {
        if (item.id) {
          otherCoverages.push({
            link: `${t('coverage.documents.forms.riders.baseUrl')}${
              item.id
            }.pdf`,
            label: item.text,
            openInNewTab: true,
          });
        }
      });
    }

    return otherCoverages;
  };

  const memberHandbooks = getMemberHandbooks();
  const summaryDocs = getSummaryDocs();
  const otherCoverages = getOtherCoverages();

  const formAccordion = {
    title: t('coverage.documents.forms.other.title'),
    items: otherCoverages,
  };

  return (
    <div data-testid="coverage-documents">
      <BreadcrumbLayout
        pageTitle={t('coverage.documents.pageName')}
        className="coverage-documents"
      >
        <div className="container coverage-documents__forms">
          {memberHandbooks.length > 1 ? (
            <LinkAccordion
              title={t('coverage.documents.forms.memberHandbook.title')}
              items={memberHandbooks}
            />
          ) : memberHandbooks.length === 1 ? (
            <ResourceLink
              to={memberHandbooks[0].link}
              text={memberHandbooks[0].label}
              openInNewTab={memberHandbooks[0].openInNewTab}
            />
          ) : null}

          {summaryDocs.map((form: FormCardProp, index) => (
            <ResourceLink
              to={form.link}
              text={form.label}
              key={index}
              openInNewTab={form.openInNewTab}
            />
          ))}

          {formAccordion.items.length > 0 && (
            <LinkAccordion {...formAccordion} />
          )}
        </div>
      </BreadcrumbLayout>
    </div>
  );
};

export default CoverageDocuments;

import './DashboardFooter.scss';
import { useTranslation } from 'react-i18next';
import useMainNavigationQuery from '../../hooks/useMainNavigationQuery';
import NavigationFooter from '../NavigationFooter/NavigationFooter';
import FooterLanguages from '../FooterLanguages/FooterLanguages';
import { useEffect } from 'react';

const DashboardFooter = (props: { onFinishedRendering: () => void }) => {
  const { i18n } = useTranslation();
  const { navigationData, isLoading } = useMainNavigationQuery(
    i18n.resolvedLanguage ?? 'en',
  );

  useEffect(() => {
    // This element id get's set from Sitefinity
    const copyrightYearElements =
      document.getElementsByClassName('copyright-year');

    if (!isLoading && navigationData) {
      for (let i = 0; i < copyrightYearElements.length; i++) {
        const yearElement = copyrightYearElements[i];
        if (yearElement) {
          yearElement.innerHTML = new Date().getFullYear().toString();
        }
      }
    }
  }, [isLoading, navigationData]);

  useEffect(() => {
    if (!isLoading) {
      // let dashboard know you're done rendering after giving it a tiny bit of time
      setTimeout(props.onFinishedRendering, 500);
    }
  }, [isLoading, props.onFinishedRendering]);

  return (
    <footer className="main-footer" data-testid="main-footer">
      <div className="main-footer__container">
        {!isLoading && navigationData
          ? navigationData.footer.map((section: any, index: number) =>
              section.className === 'footer-navigation' ? (
                <div className="footer-navigation" key={index}>
                  {section.children.map((child: any, index: number) => {
                    if (child.ViewName === '4-columns') {
                      return (
                        <NavigationFooter
                          columns={child.Children}
                          key={index}
                        />
                      );
                    } else {
                      return (
                        <div
                          key={index}
                          dangerouslySetInnerHTML={{
                            __html: child.Properties.Content,
                          }}
                        />
                      );
                    }
                  })}
                </div>
              ) : section.className === 'footer-languages' ? (
                <div className="footer-languages" key={index}>
                  {section.children.map((child: any, index: number) => (
                    <div
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: child.Properties.Content,
                      }}
                    />
                  ))}
                  <FooterLanguages key={index} />
                </div>
              ) : section.className === 'footer-bottom' ? (
                <div className="footer-bottom" key={index}>
                  {section.children.map((child: any, index: number) => (
                    <div
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: child.Properties.Content,
                      }}
                    />
                  ))}
                </div>
              ) : null,
            )
          : null}
      </div>
    </footer>
  );
};

export default DashboardFooter;
